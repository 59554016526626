$(document).ready(function () {
    var textInside = encodeURIComponent(document.title); // gets post title
    var domain = $("meta[name='web_title']").attr("content").replace(" ", "");

    $(".facebook").on("click", function (event) {
        event.preventDefault();

        var width = 575,
            height = 400,
            left = ($(window).width() - width) / 2,
            top = ($(window).height() - height) / 2,
            url =
                "http://www.facebook.com/sharer/sharer.php?u=" +
                $(this).data("url") +
                "&title=" +
                textInside,
            opts = `status=1,width=${width},height=${height},top=${top},left=${left}`;

        window.open(url, "facebook", opts);
    });

    $(".twitter").on("click", function (event) {
        event.preventDefault();

        var width = 575,
            height = 400,
            left = ($(window).width() - width) / 2,
            top = ($(window).height() - height) / 2,
            url =
                "https://twitter.com/intent/tweet?text=" +
                textInside +
                "&related=" +
                domain +
                "&url=" +
                $(this).data("url"),
            opts = `status=1,width=${width},height=${height},top=${top},left=${left}`;

        window.open(url, "twitter", opts);
    });

    function load() {
        var website = new URL(window.location.href);
        website.searchParams.delete("track_id");

        // facebook
        website.searchParams.set("utm_source", `${domain}_facebookshare`);

        $(".facebook").each(function () {
            $(this).attr("data-url", website.href);
        });

        // twitter
        website.searchParams.set("utm_source", `${domain}_twittershare`);

        $(".twitter").each(function () {
            $(this).attr("data-url", website.href);
        });
    }

    setTimeout(function () {
        load();
    }, 1000);

    setTimeout(function () {
        $(".social").fadeOut();
    }, 2000);
});
